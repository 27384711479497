import React from "react";
import { Text, Box, Image } from "@chakra-ui/react";
import wave from "../assets/wave-haikei.svg";

export const HeadingComponent = () => {
  return (
    <Box
      padding={"20px 10px 10px"}
      className="leftDiv"
      backgroundSize={"auto"}
      display={"flex"}
      justifyContent={["none", "none", "center", "center"]}
      gap={"20px"}
      alignItems={["none", "none", "center", "center"]}
      flexDirection={"column"}
      height={["280px", "280px", "100%", "100%", "100%"]}
      position={"relative"}
      backgroundRepeat={["repeat-x", "repeat-x", "repeat", "repeat"]}
      boxShadow={[
        null,
        null,
        "10px 0 8px 0 rgba(0,0,0,0.4)",
        "10px 0 8px 0 rgba(0,0,0,0.4)",
      ]}
    >
      <Box
        position={"absolute"}
        bottom={"-60px"}
        left={0}
        width={"100%"}
        height={"100px"}
        display={["block", "block", "none", "none"]}
        zIndex={-1}
      >
        {wave && (
          <Image
            filter={"drop-shadow(0 0 10px rgba(0,0,0, 0.5))"}
            width={"100%"}
            src={wave}
            alt=""
          />
        )}
      </Box>
      <Box
        mt={["35px", "35px", "0", "0"]}
        textAlign={["left", "left", "center", "center"]}
        className="animate__animated animate__fadeInRight"
        fontWeight={700}
        color={"#333333"}
      >
        <Text
          letterSpacing={"-1px"}
          as={"span"}
          lineHeight={"1.2"}
          fontSize={["40px", "45px", "55px", "50px", "60px"]}
        >
          Wisechamps <br />
          Olympiad &
        </Text>
        <Text
          mt={[1, 1, 3, 2, 4]}
          fontSize={["17px", "20px", "23px", "20px", "26px"]}
        >
          Competitive Exam Preparation Quizzes
        </Text>
      </Box>
      <Box
        style={{
          maxWidth: "850px",
          margin: "0 auto",
          height: "auto",
          padding: 0,
        }}
      >
        <Text
          mt={["-15px", "15px", "0", "0", "0"]}
          className="animate__animated animate__fadeInRight"
          borderRadius={"10px"}
          bg={"rgba(255,255,255, 0.4)"}
          padding={"10px 15px"}
          fontWeight={500}
          fontSize={["15px", "20px", "17px", "16px", "18px"]}
        >
          REGISTER WITH US &<b> AVAIL 5 FREE SESSIONS</b>
        </Text>
      </Box>
    </Box>
  );
};
