import { Box, Text } from "@chakra-ui/react";
import { useEffect } from "react";

export const CountdownTimer = ({ timeLeft, setTimeLeft }) => {
  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <Box>
      <Text fontSize={["13px", "13px", "14px", "15px"]} fontWeight={600}>
        {formatTime(timeLeft)}
      </Text>
    </Box>
  );
};
