import React, { useEffect, useState } from "react";
import {
  InputGroup,
  InputLeftAddon,
  Text,
  Box,
  Select,
  Button,
  HStack,
  PinInput,
  PinInputField,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { CountdownTimer } from "./CountdownTimer";

export const FormComponent = ({
  setEmail,
  setError,
  setLoading,
  setMode,
  setUser,
  user,
}) => {
  const query = new URLSearchParams(window.location.search);
  const [timeLeft, setTimeLeft] = useState(59);
  const [retryCount, setRetryCount] = useState(0);
  const [otp, setOtp] = useState(null);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [formOTP, setFormOTP] = useState(null);
  const [tempMode, setTempMode] = useState("");
  const [tempLoading, setTempLoading] = useState(false);
  const [refereeId, setRefereeId] = useState(query.get("refereeId") || null);
  const [registerForm, setRegisterForm] = useState({
    email: "",
    phone: "",
    student_name: "",
    student_grade: "",
    source_campaign: "",
    relation: "",
  });

  const emailRegex = new RegExp(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "gm"
  );

  const handleFormChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    setRegisterForm({
      ...registerForm,
      [name]: value,
      source_campaign: refereeId ? "Referral Community" : "Join Community",
    });
  };

  const handleOTPChange = (value) => {
    setFormOTP(value);
  };

  const verifyPhoneNumber = async (e, data) => {
    try {
      e.preventDefault();
      if (data.student_name.length < 3) {
        alert("Please Enter a Valid Student Name");
        return;
      }
      if (!emailRegex.test(data.email)) {
        alert("Please Enter a Valid Email");
        return;
      }
      if (data.phone.length < 10) {
        alert("Please Enter a Valid Phone Number");
        return;
      }
      if (!data.student_grade || data.student_grade === "") {
        alert("Please select a Valid Student Grade");
        return;
      }
      if (user.id && !data.relation) {
        alert("Please choose who referred you");
        return;
      }
      setTempLoading(true);
      const url = `https://backend.wisechamps.com/user/verify`;
      const res = await axios.post(url, {
        phone: data.phone,
        email: data.email,
      });
      if (res.data.status === 201) {
        const otp = res.data.otp;
        setTempMode("otp");
        setOtp(otp);
        setRetryCount((retryCount) => retryCount + 1);
      } else {
        setMode(res.data.mode);
      }
      setTempLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log("error is ------------", error);
    }
  };

  const handleRegisterFormClick = async (data, refereeId) => {
    try {
      setLoading(true);
      const url = `https://backend.wisechamps.com/user/add`;
      const res = await axios.post(url, {
        email: data.email,
        phone: data.phone,
        parent_name: data.student_name,
        student_name: data.student_name,
        student_grade: data.student_grade,
        referralId: refereeId || "",
        relation: data.relation || "",
        source_campaign: data.source_campaign || "Join Community",
      });
      const mode = res.data.mode;
      if (mode === "useradded") {
        setEmail(data.email);
      }
      setMode(mode);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log("error is ------------", error);
    }
  };

  const getReferralUser = async (phone) => {
    try {
      const url = `https://backend.wisechamps.com/user`;
      const resUser = await axios.post(url, { phone, referral: true });
      const mode = resUser.data.mode;
      if (mode === "user") {
        setUser(resUser.data.user);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  const handleResendOTP = async (phone) => {
    try {
      setRetryCount((retryCount) => retryCount + 1);
      if (retryCount === 3) {
        return;
      }
      setShowResendOtp(false);
      setTimeLeft(90);
      const url = `https://backend.wisechamps.com/user/resend/otp`;
      await axios.post(url, {
        phone: phone,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setShowResendOtp(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (retryCount === 3) {
      setTempMode("toomanytries");
    }
  }, [retryCount]);

  useEffect(() => {
    if (refereeId && !user.id) {
      getReferralUser(refereeId);
    }
  }, []);

  if (tempMode === "toomanytries") {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box
          textAlign={"left"}
          padding={["1rem", "1rem", "2rem", "3rem"]}
          maxWidth={"450px"}
          width={"100%"}
          mt={["70px", "80px", "0", "0"]}
        >
          <Text
            fontSize={["20px", "25px", "25px", "25px", "25px"]}
            width={"100%"}
            fontWeight={600}
            textAlign={"center"}
          >
            Too many requests <br />
            Please try after some time
          </Text>
        </Box>
      </Box>
    );
  }
  if (tempMode === "otp") {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box
          textAlign={"left"}
          padding={["1rem", "1rem", "2rem", "3rem"]}
          maxWidth={"450px"}
          width={"100%"}
          borderRadius={"10px"}
          boxShadow={[
            null,
            null,
            "0 0 10px 2px #00000030",
            "0 0 10px 2px #00000030",
          ]}
        >
          <Text
            fontSize={["20px", "25px", "25px", "25px", "25px"]}
            width={"100%"}
            fontWeight={600}
          >
            Enter 6 digit OTP
          </Text>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text
              fontSize={["13px", "13px", "14px", "15px"]}
              fontWeight={500}
              mt={"5px"}
            >
              Sent to +91{" "}
              <Text as={"span"}>
                {registerForm.phone.substring(
                  registerForm.phone.length - 10,
                  registerForm.phone.length
                )}
              </Text>
            </Text>
            <CountdownTimer setTimeLeft={setTimeLeft} timeLeft={timeLeft} />
          </Flex>
          <HStack justifyContent={"center"} mt={10}>
            <PinInput
              manageFocus={true}
              autoFocus={true}
              type="number"
              otp
              size={["md", "md", "lg", "lg"]}
              onChange={(event) => handleOTPChange(event)}
              isInvalid={otp !== formOTP && formOTP?.length === 6}
              focusBorderColor={
                formOTP?.length === 6 && otp === formOTP
                  ? "#0DD20D"
                  : formOTP?.length === 6 && otp !== formOTP
                  ? "red.500"
                  : "#4e46e4"
              }
            >
              <PinInputField
                border={
                  otp === formOTP && formOTP?.length === 6
                    ? "2px solid #0DD20D"
                    : null
                }
              />
              <PinInputField
                border={
                  otp === formOTP && formOTP?.length === 6
                    ? "2px solid #0DD20D"
                    : null
                }
              />
              <PinInputField
                border={
                  otp === formOTP && formOTP?.length === 6
                    ? "2px solid #0DD20D"
                    : null
                }
              />
              <PinInputField
                border={
                  otp === formOTP && formOTP?.length === 6
                    ? "2px solid #0DD20D"
                    : null
                }
              />
              <PinInputField
                border={
                  otp === formOTP && formOTP?.length === 6
                    ? "2px solid #0DD20D"
                    : null
                }
              />
              <PinInputField
                border={
                  otp === formOTP && formOTP?.length === 6
                    ? "1px solid #0DD20D"
                    : null
                }
              />
            </PinInput>
          </HStack>
          {showResendOtp ? (
            <Box mt={6}>
              <Text
                fontWeight={500}
                color={"#696969"}
                fontSize={["13px", "13px", "14px", "15px"]}
              >
                Did not receive an OTP ?
              </Text>

              <Text
                fontWeight={600}
                color={"#5838fc"}
                fontSize={["14px", "14px", "15px", "16px"]}
                mt={"5px"}
                border={"1px solid #5838fc"}
                borderRadius={"5px"}
                width={"max-content"}
                padding={"5px 10px"}
                cursor={"pointer"}
                onClick={() => handleResendOTP(registerForm.phone)}
              >
                Resend OTP
              </Text>
            </Box>
          ) : null}
          <Button
            mt={showResendOtp ? 7 : 10}
            isLoading={formOTP?.length === 6 && otp === formOTP ? false : true}
            loadingText={
              formOTP?.length === 6 && otp !== formOTP
                ? "Invalid OTP"
                : "Submit"
            }
            spinnerPlacement="none"
            textAlign={"center"}
            width={"100%"}
            id={
              formOTP?.length === 6 && otp === formOTP
                ? "submit-btn"
                : "submit-btn-loading"
            }
            border="none"
            cursor="pointer"
            onClick={() => handleRegisterFormClick(registerForm, user.id)}
          >
            <Text
              fontWeight={600}
              fontSize={[
                "13px !important",
                "13px !important",
                "14px !important",
                "15px !important",
              ]}
            >
              Submit
            </Text>
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <form className="input-container">
        <Text
          fontSize={["20px", "25px", "30px", "25px", "30px"]}
          width={"100%"}
          fontWeight={600}
        >
          Register Now
        </Text>
        <InputGroup>
          <input
            type="text"
            inputMode="text"
            onChange={handleFormChange}
            className="input-field"
            name="student_name"
            id="student_name"
            required
            placeholder="Student Name"
          />
          <label htmlFor="student_name" className="input-label">
            Student Name
          </label>
          <Text as={"span"} className="input-highlight"></Text>
        </InputGroup>
        <InputGroup>
          <input
            type="email"
            inputMode="email"
            onChange={handleFormChange}
            className="input-field"
            name="email"
            id="email"
            required
            placeholder="Email"
          />
          <label htmlFor="email" className="input-label">
            Email
          </label>
          <Text as={"span"} className="input-highlight"></Text>
        </InputGroup>
        <InputGroup>
          <InputLeftAddon
            fontSize={"13px"}
            fontWeight={"600"}
            padding={"20px 15px"}
            color={"#4e46e4"}
          >
            +91
          </InputLeftAddon>
          <input
            placeholder="Phone Number"
            className="input-field"
            type="tel"
            inputMode="tel"
            id="phone"
            name="phone"
            maxLength={10}
            onChange={handleFormChange}
            required
          />
          <label htmlFor="phone" className="input-label">
            Phone Number
          </label>
          <Text as={"span"} className="input-highlight"></Text>
        </InputGroup>
        <InputGroup>
          <Select
            className={"input-field"}
            onChange={handleFormChange}
            name="student_grade"
            id="student_grade"
            placeholder="Student Grade"
          >
            <option value={"1"}>Grade 1</option>
            <option value={"2"}>Grade 2</option>
            <option value={"3"}>Grade 3</option>
            <option value={"4"}>Grade 4</option>
            <option value={"5"}>Grade 5</option>
            <option value={"6"}>Grade 6</option>
            <option value={"7"}>Grade 7</option>
            <option value={"8"}>Grade 8</option>
          </Select>
        </InputGroup>
        <InputGroup display={user.id ? "block" : "none"}>
          <Select
            className="input-field"
            placeholder="Who Referred You"
            onChange={handleFormChange}
            name="relation"
            id="relation"
            required
          >
            <option value={"Friend"}>Friend</option>
            <option value={"Cousin"}>Cousin</option>
          </Select>
        </InputGroup>
        <Button
          textAlign={"center"}
          width={"100%"}
          id="submit-btn"
          border="none"
          cursor="pointer"
          onClick={(e) => verifyPhoneNumber(e, registerForm)}
          isLoading={tempLoading}
          loadingText="Sending OTP"
        >
          <Text fontWeight={600} fontSize={["13px", "13px", "14px", "15px"]}>
            Submit
          </Text>
        </Button>
      </form>
    </Box>
  );
};
