import { useState } from "react";
import "./App.css";
import { Text, Box, SimpleGrid, Button } from "@chakra-ui/react";
import { Header } from "./Components/Header";
import { HeadingComponent } from "./Components/HeadingComponent";
import { FormComponent } from "./Components/FormComponent";
import { ring } from "ldrs";
ring.register();

export const App = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("");
  const [email, setEmail] = useState();
  const [user, setUser] = useState({});

  const handleGroupLinkClick = async () => {
    window.location.href = "https://chat.whatsapp.com/GkjhNsOtLMMHESyciLUwtM";
  };

  if (loading) {
    return (
      <Box
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        height={["85vh", "90vh", "100vh"]}
      >
        <l-ring
          size="50"
          stroke="6"
          bg-opacity="0.4"
          speed="2"
          color="#5838fc"
        ></l-ring>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        height={["80vh", "80vh", "90vh"]}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"1rem"}
      >
        <Text
          fontWeight={600}
          fontSize={["20px", "22px", "25px", "22px", "25px"]}
        >
          Something Went Wrong. Please try again..
        </Text>
      </Box>
    );
  }

  if (mode === "duplicateuser") {
    return (
      <div
        className="quizSubmitted"
        style={{
          height: "85vh",
        }}
      >
        <Text
          as={"h1"}
          mt={0}
          fontWeight={600}
          fontSize={["20px", "22px", "25px", "22px", "25px"]}
        >
          OPPS!
        </Text>
        <Text fontSize={["14px", "16px", "18px", "16px", "18px"]}>
          It looks like you are already registered with us.
          <br />
          Please join our whatsapp group to get all the quiz details.
        </Text>
        <Button
          mt={3}
          fontSize={["13px", "13px", "14px", "14px"]}
          id="submit-btn"
          onClick={handleGroupLinkClick}
        >
          Join Our Whatsapp Group
        </Button>
      </div>
    );
  }

  if (mode === "useradded") {
    setTimeout(() => {
      window.location.assign(`https://students.wisechamps.com?email=${email}`);
    }, 5000);
    return (
      <div
        className="quizSubmitted"
        style={{
          height: "85vh",
          maxWidth: "750px",
          margin: "0 auto",
        }}
      >
        <Text
          as={"h1"}
          mt={0}
          mb={"10px"}
          fontWeight={600}
          fontSize={["25px", "26px", "27px", "26px", "27px"]}
        >
          Thank You
        </Text>
        <Text fontSize={["14px", "16px", "18px", "16px", "18px"]}>
          <Text>
            We have shared the group joining link on your registered whatsapp
            number.
          </Text>
          <Text mt={"10px"}>
            Join the whatsapp group to get the sessions schedule and joining
            details. <b>5 free sessions have been added to the account.</b>
          </Text>
        </Text>
        <Button
          mt={5}
          fontSize={["13px", "13px", "14px", "14px"]}
          id="submit-btn"
          onClick={handleGroupLinkClick}
        >
          Join Our Whatsapp Group
        </Button>
      </div>
    );
  }

  return (
    <Box>
      <Header />
      <SimpleGrid
        width={"100%"}
        minHeight={["90vh", "90vh", "100vh", "100vh", "100vh"]}
        overflowX={"hidden"}
        gridTemplateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        alignContent={["start", "start", "unset"]}
        gap={["40px", "100px", 0]}
      >
        <HeadingComponent />
        <FormComponent
          setEmail={setEmail}
          setError={setError}
          setLoading={setLoading}
          setMode={setMode}
          setUser={setUser}
          user={user}
        />
      </SimpleGrid>
    </Box>
  );
};
