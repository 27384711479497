import React from "react";
import logo from "../assets/logo.png";

export const Header = () => {
  return (
    <header
      style={{
        position: "absolute",
        zIndex: "99",
        top: "10px",
        left: "10px",
      }}
      className="animate__animated animate__fadeInLeft"
    >
      <img src={logo} alt="Wisechamps" width={"150px"} />
    </header>
  );
};
